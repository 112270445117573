/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/exhaustive-deps */
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { analytics } from '../config/analytics';

import { AnimatePresence, domAnimation, LazyMotion, motion } from 'framer-motion';
import 'styles/globals.scss';
import { useEffect, useState } from 'react';
import PopupProvider from 'context/PopupProvider';
import useFoucFix from 'hooks/useFocusFix';

const MyApp = ({ Component, pageProps, router }: AppProps) => {
  useFoucFix();

  const [showAd, setShowAd] = useState(true);

  useEffect(() => {
    analytics(window, document, 'script', 'dataLayer', 'GTM-5KLWVL4');
  });

  return (
    <PopupProvider.Provider value={{ showAd, setShowAd }}>
      <>
        <Head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="initial-scale=1.0, width=device-width" />
          <meta name="robots" content="follow, index" />
          <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
          <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
          <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
          <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
          <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png" />
          <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png" />
          <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png" />
          <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png" />
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />
          <link rel="icon" type="image/png" sizes="192x192" href="/android-icon-192x192.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/manifest.json" />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
          <meta name="theme-color" content="#ffffff" />
        </Head>
        <LazyMotion features={domAnimation}>
          <AnimatePresence exitBeforeEnter={true}>
            <motion.div key={`main:${router.pathname}`}>
              <div className="overlay">
                <svg
                  key={`svg:${router.pathname}`}
                  width="100%"
                  height="100%"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="none"
                >
                  <motion.path
                    exit={{
                      d: [
                        'M 0 100 V 100 Q 50 100 100 100 V 100 z',
                        'M 0 100 V 50 Q 50 0 100 50 V 100 z',
                        'M 0 100 V 0 Q 50 0 100 0 V 100 z',
                      ],
                    }}
                    transition={{
                      duration: 0.9,
                      ease: ['easeIn', 'easeOut'],
                    }}
                    className="overlay__path"
                    style={{
                      fill: 'rgb(12,35,64)',
                    }}
                    vectorEffect="non-scaling-stroke"
                    d="M 0 100 V 100 Q 50 100 100 100 V 100 z"
                  />
                </svg>
              </div>
              {/* @ts-ignore */}
              <Component {...pageProps} />
            </motion.div>
          </AnimatePresence>
        </LazyMotion>
      </>
    </PopupProvider.Provider>
  );
};

export default MyApp;
