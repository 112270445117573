var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"a8d6aad8cadff9ed2395e9ac0ab90b3840bf2c95"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 0,
  ignoreErrors: [
    'ReferenceError',
    'Error: WebWorker: writer not initialised. Service Should be Started.',
    'ResizeObserver loop limit exceeded',
    '3000ms timeout exceeded',
    'NetworkError: A network error occurred',
  ],
  beforeSend: function (event) {
    // filter out UnhandledRejection errors that have no information
    if (event?.exception?.values !== undefined && event.exception.values.length === 1) {
      const e = event.exception.values[0];
      if (e.type === 'UnhandledRejection' && e.value === 'Non-Error promise rejection captured with value: ') {
        return null;
      }
    }
  },
});
